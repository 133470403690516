import { mapMutations } from 'vuex'

export const recaptcha = {
  methods: {
    ...mapMutations(['setError', 'setNotice', 'setLoading']),

    async performRecaptcha() {
      if (process.env.VUE_APP_FIREBASE_EMULATOR === 'true') return true

      this.setLoading(true)
      this.setNotice('LOADING_WAIT')

      return true
    },
  },
}
