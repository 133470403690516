import { mapState, mapActions } from 'vuex'
import SButton from '../../../components/ui/s-button'
import STitle from '../../../components/ui/s-title'
import { recaptcha } from '../../../mixins/recaptcha'
import searchCompanyImg from '@/assets/images/search_company_home.svg'
import lockerIcon from '@/assets/images/icone_locker.svg'

export default {
  name: 'WelcomeSearchCompany',
  mixins: [recaptcha],

  data() {
    return {
      showError: false,
      showSuccess: false,
      tryAgain: false,
      iconError: false,
      iconSuccess: false,
      companyName: '',
      loading: false,
      searchCompanyImg,
      lockerIcon,
      companies: [],
      selectedCompany: {},
    }
  },

  computed: {
    ...mapState(['error', 'success', 'company']),

    disabledButton() {
      if (
        this.companies.length > 0 &&
        !Object.keys(this.selectedCompany).length
      ) {
        return true
      }

      return !this.companyName || this.loading
    },

    wrongCompanyName() {
      return ` "${this.companyName}". `
    },

    companyTerm() {
      return this.companyName.trim().toLowerCase()
    },
  },

  methods: {
    ...mapActions(['getCompaniesByTerm']),

    changeCompany(company) {
      this.selectedCompany = company
      this.companyName = company.name
      this.$i18n.locale = company.lang || 'pt-BR'
    },

    alertErroSearchCompany() {
      this.loading = false
      this.showSuccess = false
      this.iconSuccess = false
      this.showError = true
      this.iconError = true
      this.tryAgain = true
    },

    alertSuccessSearchCompany() {
      this.loading = false
      this.showError = false
      this.showSuccess = true
      this.iconSuccess = true
      this.tryAgain = false
    },

    async searchCompany() {
      this.loading = true

      this.companies = await this.getCompaniesByTerm(this.companyTerm)

      if (this.companies.length === 0) {
        this.alertErroSearchCompany()
        return
      }

      if (this.companies.length === 1) {
        const [company] = this.companies
        this.changeCompany(company)
      }

      this.alertSuccessSearchCompany()
    },

    goToCompanyPage() {
      const { id, slug } = this.selectedCompany

      this.$router.push({
        name: 'company-splash',
        params: { companyId: id, slug },
      })
    },

    currentAction() {
      if (!this.companyTerm || this.companyTerm === '') {
        return
      }

      if (this.showSuccess) {
        this.goToCompanyPage()
      } else {
        this.searchCompany()
      }
    },

    cleanFeedback() {
      this.showError = false
      this.iconError = false
      this.showSuccess = false
      this.iconSuccess = false
      this.tryAgain = false
    },
  },

  components: {
    SButton,
    STitle,
  },
}
